<template>
  <div ref="elementRef" class="relative grid h-screen" @click="handlePromotionClick">
    <div class="relative h-100dvh grid-area-stack">
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        :lazy
        fit="cover"
        :props-img="{ class: 'h-full' }"
        class="full"
      />
      <base-video
        v-if="responsiveMedia.video"
        v-bind="video"
        ref="videoRef"
        :autoplay="!lazy"
        :controls="false"
        loop
        class="full cover"
      />
      <div class="absolute-0" style="background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, #000000 100%);" />
    </div>
    <div class="relative mt-a w-full px-4 pb-10 grid-area-stack lg:px-10">
      <h1 v-if="title" class="elevate-title-2 c-white">
        {{ title }}
      </h1>
      <p v-if="subtitle" class="elevate-body-3 lg:elevate-body-2 mt-4 c-white">
        {{ subtitle }}
      </p>
      <div
        v-if="linkType !== 'No-CTA' && targets.length"
        class="gap-4 i-flex not-first:mt-6"
      >
        <div
          v-style="equalTargetStyle"
          class="gap-4"
          :class="isEqualWidthButtons ? 'grid' : 'flex wrap'"
        >
          <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size />
        </div>
      </div>
      <button
        v-if="videoRef"
        :aria-label="paused ? $t.videoPlay : $t.videoPause"
        class="absolute bottom-0 right-0 mb-10 mr-4 p-2 c-white duration lg:mb-14 lg:mr-10 @hactive:c-grey-60"
        @click="togglePlayback"
      >
        <vf-icon :name="paused ? 'play' : 'pause'" size="md" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey } from '#content/components/cms/section/context'
import type { HeroContent } from '#types/components/cms/hero'
import type { BaseVideo as BaseVideoPropType } from '#types/components/base/video'

const { content } = defineProps<{
  content: HeroContent
}>()

const {
  equalTargetSize,
  linkType,
  media,
  promotionTracking,
  subtitle,
  targets = [],
  title
} = content

const { size } = useAppConfig().components.cms.sharedButton
const { getMedia, getVideo } = useCms()
const {
  elementRef,
  handlePromotionClick
} = usePromotionTracking(promotionTracking, inject(SectionContextKey), content.name)

const lazy = inject(LazyMedia)
const responsiveMedia = getMedia(media)
const paused = ref(false)
const video = ref<BaseVideoPropType>({ src: '' })
const videoRef = ref<{ $el: HTMLVideoElement }>()

const togglePlayback = () => {
  paused.value ? videoRef.value?.$el.play() : videoRef.value?.$el.pause()
  paused.value = !paused.value
}

const isEqualWidthButtons = getValueForBreakpoint('sm', equalTargetSize)

const equalTargetStyle = {
  ...(isEqualWidthButtons && {
    'grid-cols': {
      sm: '1fr',
      md: `repeat(${targets.length}, minmax(0,1fr))`
    }
  })
}

onMounted(() => {
  if ('video' in responsiveMedia) {
    video.value = getVideo(responsiveMedia.video) as BaseVideoPropType
    if (videoRef.value) {
      videoRef.value.$el.load()
      videoRef.value.$el.play()
    }
  }
})
</script>
